import React, { useState } from "react";
import "./styles.scss";

type Props = {};

const Footer = (props: Props) => {
  return (
    <div className="bottomFooterWrapper">
      <div className="bottomFooterLinkRow">
        <a href="https://web.aplano.de/" className="bottomFooterLink px-10">
          Login
        </a>
        <a
          href="https://www.aplano.de/registrieren"
          className="bottomFooterLink px-10"
        >
          Registrieren
        </a>
        <a
          href="https://www.aplano.de/kontakt"
          className="bottomFooterLink px-10"
        >
          Kontakt
        </a>
        <a href="https://www.aplano.de/blog" className="bottomFooterLink px-10">
          Unser Blog
        </a>
        <a href="https://www.aplano.de/faq" className="bottomFooterLink px-10">
          FAQ
        </a>
        <a href="https://www.aplano.de/agb" className="bottomFooterLink px-10">
          Unsere AGB
        </a>
      </div>
    </div>
  );
};

export default Footer;
